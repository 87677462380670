module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DevOps Party Games","short_name":"DevOps Party Games","start_url":"/","background_color":"#f7f9fb","theme_color":"#4c5269","display":"standalone","icon":"static/images/icons/favicon.svg","icons":[{"src":"/images/icons/favicon.svg","type":"image/svg","sizes":"192x192"},{"src":"/images/icons/icon_512.png","type":"image/png","sizes":"512x512"},{"src":"/images/icons/maskable_icon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"17aedb5c5a408bc3e7c3cda48dfb9f91"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-167098414-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{"pages":"/opt/build/repo/src/templates/page-mdx.js","default":"/opt/build/repo/src/templates/page-mdx.js"},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

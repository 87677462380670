// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-old-scoreboard-mdx": () => import("./../../../src/pages/old-scoreboard.mdx" /* webpackChunkName: "component---src-pages-old-scoreboard-mdx" */),
  "component---src-templates-episode-js": () => import("./../../../src/templates/episode.js" /* webpackChunkName: "component---src-templates-episode-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-people-js": () => import("./../../../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-thought-leaderboard-js": () => import("./../../../src/templates/thought-leaderboard.js" /* webpackChunkName: "component---src-templates-thought-leaderboard-js" */)
}

